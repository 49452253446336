// TODO: We should be using the environment files and build to an environment.
// for an example look at the FR project's admin angular app.
export const API_ROOT = 'https://api.cottonwoodcabinets.com';
export const HIDE_STAGING_INDICATOR = true;

/** Shows some debug buttons throughout the app */
export const ALLOW_DEBUG = false;

/** 5 hours */
export const JOBS_REFRESH_TIMER_DURATION = 1000 * 60 * 60 * 5;
